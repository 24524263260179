/* eslint-disable */
import React from 'react'
import Helmet from '../../components/Helmet'

import { graphql } from 'gatsby'

import coupleGardening from '../../images/heroes/couple-gradening.jpg'
import coupleGardeningMobile from '../../images/heroes/couple-gradening-mobile.jpg'

import { StaticImage } from 'gatsby-plugin-image'

import aetna from '../../images/brand-logos/aetna.svg'
import cigna from '../../images/brand-logos/cigna.svg'
import regence from '../../images/brand-logos/regence.svg'
import united from '../../images/brand-logos/united-healthcare.svg'
import wellcare from '../../images/brand-logos/wellcare.svg'
import mutual from '../../images/brand-logos/mutual-omaha.svg'

import ResponsiveGrid from '../../components/GridLayouts/ResponsiveGrid'

import {
  Accordion,
  AvailabilityForm,
  Billboard,
  Column,
  Columns,
  LinkButton,
  List,
  ListItem,
  Stack,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import header from '../../components/Header'
import footer from '../../components/Footer'
import headerData from '../../data/headerData.js'
import isCallCenterOpen from '../../../../../utils/isOpen'

const Home = ({ data }) => {
  const brandyList = ['disclaimer-hcp-medicareadvantagelp']
  const disclaimerList = brandyList
    .map((disclaimerId) =>
      data.allBrandyDisclaimer.edges.find(
        ({ node }) => node.brandy_id === disclaimerId
      )
    )
    .filter((node) => node)

  const footerDisclaimers = disclaimerList.map(({ node }) => {
    return {
      brandy: {
        text: node.text,
        symbol: node.symbol,
        key: node.brandy_id,
      },
    }
  })

  const brandLogos = [
    { src: aetna, alt: 'Aetna logo' },
    { src: cigna, alt: 'Cigna logo' },
    { src: regence, alt: 'Regence logo' },
    { src: united, alt: 'United Healthcare logo' },
    { src: wellcare, alt: 'Wellcare logo' },
    { src: mutual, alt: 'Mutual of Omaha logo' },
  ]

  const { rotatedNumber, requestId } = useMapi()

  const layoutProps = {
    data: {
      contentfulPage: {
        header: header(),
        footer: footer(brandyList, data.allBrandyDisclaimer.edges),
        seo: {
          title: 'Medicare Part D Plans | Healthcareplans.com ',
          description:
            'Medicare Part D provides prescription drug coverage for medications. Consult with our experts to choose the right Medicare Part D plan.',
          canonical: 'https://www.healthcareplans.com/plans/medicare-part-d/',
          robots: 'follow,index',
        },
        path: '/plans/medicare-part-d',
        promoCode: '170085',
        ringPool: 'MED',
        hideStickyCTA: false,
        spanish: false,
        sections: [
          {
            // Add any relevant section data
          },
        ],
      },
      site: {
        siteMetadata: {
          siteURL: 'https://healthcareplans.com/',
          siteName: 'healthcareplans',
          alternateName: 'healthcareplans',
          removeTrailingSlashes: true,
        },
      },
    },
    defaultRobots: 'follow,index',
    main: (
      <>
        <Helmet />
        <div className="hero-content">
          <div className="wrapper">
            <Billboard
              variant="full"
              textColor="Dark"
              image={
                <img
                  src={coupleGardening}
                  alt="an elderly couple tends plants in garden and smile"
                />
              }
              mobileImage={
                <img
                  src={coupleGardeningMobile}
                  alt="an elderly couple tends plants in garden and smile"
                />
              }
              mainContent={
                <>
                  <Typography variant="h1">
                    Explore Medicare Part D Prescription Drug Plans (PDPs)
                  </Typography>

                  <Typography variant="h4">
                    Medicare Part D plans provide prescription drug coverage,
                    and are available to those enrolled in Original Medicare
                    (Parts A & B), Medicare Advantage (Part C), and Medigap
                    plans.
                  </Typography>

                  {isCallCenterOpen() ? (
                    <LinkButton
                      variant="feature"
                      to={`tel:${rotatedNumber}`}
                      color="primary"
                      className=""
                      requestId={requestId}
                    >
                      Call Now {rotatedNumber}
                    </LinkButton>
                  ) : (
                    <AvailabilityForm
                      placeholderText="Zip Code"
                      buttonText="Find a Medicare Part D Plan"
                      buttonColor="primary"
                      butttonBackgroundColor="black"
                      className="avail-form"
                    />
                  )}
                </>
              }
            />
          </div>
        </div>

        <VariableContent
          alignMainContent="Center"
          alignImageToBottom={false}
          centeredContent={false}
          backgroundColor="light"
          customLink={null}
          className="brand-logos header"
          mainContent={
            <Typography variant="h2">
              We partner with these leading insurance brands{' '}
            </Typography>
          }
        >
          <ResponsiveGrid
            images={brandLogos}
            numberOfColumns={6}
            numberOfMobileColumns={2}
            constraint="large"
            columnGap="10px"
          />
        </VariableContent>

        <VariableContent
          alignMainContent="left"
          backgroundColor="white"
          mainContent={
            <>
              <Typography variant="h2">
                Medicare Part D coverage and tiers
              </Typography>
              <Typography>
                For many Medicare enrollees, a Medicare Part D Prescription Drug
                Plan (PDP) is the most cost-effective way to obtain prescription
                medication. Some Medicare Advantage (Part C) plans also cover
                certain prescription drugs, but if you take advantage of
                Original Medicare (Parts A & B) via the federal government, a
                Part D PDP may be just what the doctor ordered—pun very much
                intended—for low-cost drug coverage.
              </Typography>
              <Typography>
                Part D PDPs vary by state and insurance carrier, but most offer
                drug coverage based on a tiered system. Below is an example of a
                tier system you might find when shopping for a plan—you’ll want
                to make sure that the drugs you already take or may need soon
                are covered by the tiers in the plan you choose.
              </Typography>
            </>
          }
          style={{
            color: '#1b0b54',
          }}
        >
          <Stack>
            <Columns leftAligned>
              <Column>
                <Typography variant="h5">Tier 1</Typography>
                <Typography variant="h6">Preferred Generic</Typography>
                <Typography variant="body">
                  These are commonly available medications produced by many
                  manufacturers. The lowest copayment will be for the
                  lowest-cost version of the drug.
                </Typography>
              </Column>
              <Column>
                <Typography variant="h5">Tier 2</Typography>
                <Typography variant="h6">Generic</Typography>
                <Typography variant="body">
                  These medications are similar to those in Tier 1, but there
                  may be fewer options available and the copayments slightly
                  higher.
                </Typography>
              </Column>
              <Column>
                <Typography variant="h5">Tier 3</Typography>
                <Typography variant="h6">Preferred Brand</Typography>
                <Typography variant="body">
                  These are brand-name drugs that may not be available in
                  generic form, but are cost-effective compared to other
                  alternatives, and usually require a medium-sized copayment.
                </Typography>
              </Column>
              <Column>
                <Typography variant="h5">Tier 4</Typography>
                <Typography variant="h6">Non-Preferred Drug</Typography>
                <Typography variant="body">
                  These cost more than preferred brand drugs. Some plans may not
                  cover these, especially if there is a generic
                  equivalent—otherwise, there may be a high copayment.
                </Typography>
              </Column>
              <Column>
                <Typography variant="h5">Tier 5</Typography>
                <Typography variant="h6">Speciatlty Tier</Typography>
                <Typography variant="body">
                  These drugs used to treat ongoing health conditions may have
                  to be ordered through a specialty pharmacy, and if covered,
                  will have very high copayments.
                </Typography>
              </Column>
              <Column>
                <Typography variant="h5">Tier 6</Typography>
                <Typography variant="h6">Select Care Drugs</Typography>
                <Typography variant="body">
                  This special tier of prescription drug often has a $0 or very
                  low copayment, and is reserved for drugs that treat common
                  senior-specific conditions such as diabetes, hypertension, and
                  osteoporosis.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="center"
          mainContent={
            <>
              <span className="show-on-desktop margin-bottom-sm">
                <StaticImage
                  src="../../images/icons/trustpilot-reviews-desktop.svg"
                  alt="TrustPilot reviews icon"
                  layout="constrained"
                />
              </span>
              <span className="show-on-mobile margin-bottom-sm">
                <StaticImage
                  src="../../images/icons/trustpilot-reviews-mobile.svg"
                  alt="TrustPilot reviews icon"
                  layout="constrained"
                />
              </span>
              <a href="https://www.trustpilot.com/review/clearlinkinsurance.com">
                View Trustpilot reviews
              </a>
            </>
          }
        />

        <VariableContent
          backgroundColor="dark"
          mainContent={
            <>
              <Typography variant="h2">
                Find a Medicare Part D plan today
              </Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column backgroundColor="white" className="card-padding">
                <Typography variant="h5">
                  Find a Medicare Part D plan online
                </Typography>
                <Typography variant="body">
                  Use our Medicare plan finder tool to compare plans in your
                  location—and enroll online during a qualifying enrollment
                  period.
                </Typography>
                <div className="avail-form margin-left-0">
                  <AvailabilityForm
                    placeholderText="Zip Code"
                    buttonText="Find a Medicare Part D Plan"
                    buttonColor="primary"
                    butttonBackgroundColor="black"
                  />
                </div>
              </Column>
              <Column backgroundColor="white" className="card-padding">
                <Typography variant="h5">Give us a call</Typography>
                <LinkButton
                  variant="feature"
                  to={`tel:${rotatedNumber}`}
                  color="primary"
                  className=""
                  requestId={requestId}
                >
                  Call Now {rotatedNumber} | TTY 711
                </LinkButton>
                <Typography variant="body">
                  Our licensed agents are available Monday through Friday from
                  8:30 am to 5:00 pm MT.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>

        <VariableContent
          alignMainContent="left"
          mainContent={
            <Typography variant="h2">
              Frequently asked questions about Medicare Part D
            </Typography>
          }
          className="faq-section-padding"
          style={{ color: '#1B0B54' }}
        >
          <Stack spacing="xxl" alignMainContent="center">
            <Accordion
              items={[
                {
                  title: 'How do I enroll in Medicare Part D?',
                  content: (
                    <>
                      <Typography>
                        To enroll in Medicare Part D prescription drug coverage,
                        you must be eligible for Medicare. Medicare is generally
                        available to those age 65 and older in the United
                        States; you may also qualify before 65 as the result of
                        certain conditions:
                      </Typography>
                      <List>
                        <ListItem>Some disabilities</ListItem>
                        <ListItem>
                          End-stage renal disease (permanent kidney failure
                          requiring dialysis or transplant)
                        </ListItem>
                        <ListItem>
                          Amyotrophic lateral sclerosis (ALS, also known as Lou
                          Gehrig’s disease)
                        </ListItem>
                      </List>
                      <Typography>
                        To enroll in a Medicare Prescription Drug Plan (PDP),
                        use our Medicare plan comparison tool to find the plan
                        that works best for you and enroll online during a
                        qualifying enrollment period.
                      </Typography>
                    </>
                  ),
                },
                {
                  title: 'When do I enroll in Medicare Part D?',
                  content: (
                    <>
                      <Typography>
                        You can enroll in a Medicare Part D PDP during a
                        qualified{' '}
                        <a href="/resources/medicare-enrollment-101/">
                          Medicare Enrollment Period
                        </a>
                        :
                      </Typography>
                      <List>
                        <ListItem>
                          Initial Enrollment Period - beginning 3 months before
                          your 65th birthday, continuing through your birthday
                          month and three months after. During this period,
                          after signing up for Original Medicare, you can enroll
                          in a Prescription Drug Plan
                        </ListItem>
                        <ListItem>
                          <a href="/resources/when-is-medicare-advantage-annual-enrollment/">
                            Annual Enrollment Period
                          </a>{' '}
                          - October 15-December 7 each year, available to anyone
                          Medicare-eligible. During this period, you can enroll
                          in Part D coverage for the first time or change or
                          drop your PDP.
                        </ListItem>
                        <ListItem>
                          <a href="/resources/gep-oep-enrollment-periods/">
                            Medicare Advantage Open Enrollment Period
                          </a>{' '}
                          - January 1-March 31 each year, available to anyone
                          already enrolled in a Medicare Advantage plan. During
                          this period, you can add or drop prescription drug
                          coverage.
                        </ListItem>
                        <ListItem>
                          <a href="/resources/special-enrollment-period-chart/">
                            Special Enrollment Periods
                          </a>{' '}
                          - may occur throughout the year, triggered by
                          qualifying events. Depending on your circumstances,
                          you may be able to add, drop, or change your
                          prescription drug coverage.
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'Who is eligible for Medicare Part D?',
                  content: (
                    <>
                      <Typography>
                        Medicare Part D Prescription Drug Plans (PDPs) are
                        available to anyone eligible for Original Medicare
                        (Medicare Parts A & B, coverage provided by the federal
                        government). Medicare eligibility is determined by age
                        or by special qualifying conditions.
                      </Typography>
                      <List>
                        <ListItem>
                          <strong>Age:</strong> If you are a citizen of the
                          United States who has been living in the U.S. for the
                          previous 5 years and are 65 years of age (or
                          approaching your 65th birthday) you are eligible to
                          sign up for Medicare.
                        </ListItem>
                        <ListItem>
                          <strong>Qualifying Conditions:</strong> You may
                          qualify for early benefits if
                          <List>
                            <ListItem>
                              you are disabled and have received Social Security
                              benefits for at least 24 consecutive months;
                            </ListItem>
                            <ListItem>
                              you suffer from kidney failure, also known as
                              End-Stage Renal Disease (ESRD), and have had a
                              kidney transplant or been on dialysis for 3
                              months; or
                            </ListItem>
                            <ListItem>
                              you have amyotrophic lateral sclerosis (ALS or Lou
                              Gehrig’s disease).
                            </ListItem>
                          </List>
                        </ListItem>
                      </List>
                    </>
                  ),
                },
                {
                  title: 'How much does Medicare Part D cost?',
                  content: (
                    <>
                      <Typography>
                        The cost of Medicare Part D coverage varies by plan. Use
                        our comparison tool to find a plan that best fits your
                        budget. You can even search by particular prescriptions
                        to find plans that cover your medications.
                      </Typography>
                    </>
                  ),
                },
              ]}
            />
          </Stack>
        </VariableContent>

        <VariableContent
          backgroundColor="light"
          mainContent={
            <>
              <Typography variant="h2">Medicare resources</Typography>
            </>
          }
          alignMainContent="center"
        >
          <Stack>
            <Columns>
              <Column>
                <StaticImage
                  src="../../images/people/cute-couple.jpg"
                  alt="cute elderly couple"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/gep-oep-enrollment-periods/"
                    style={{ color: '#1b0b54' }}
                  >
                    General Enrollment and Open Enrollment Periods
                  </a>
                </Typography>
                <Typography variant="body">
                  Learn about the two Medicare enrollment periods that run from
                  January through March.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/grandparents-on-couch-with-grandkids.jpg"
                  alt="elederly couple sit with grandkids on couch and smile"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/when-is-medicare-advantage-annual-enrollment/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Annual Enrollment Period 101
                  </a>
                </Typography>
                <Typography variant="body">
                  When is the Medicare Annual Enrollment Period? Get answers to
                  this and other AEP-related questions.
                </Typography>
              </Column>
              <Column>
                <StaticImage
                  src="../../images/people/biking-on-beach.jpg"
                  alt="an elderly couple rides bicycles on the beach"
                  layout="constrained"
                />
                <Typography variant="h5" style={{ marginTop: '16px' }}>
                  <a
                    href="/resources/medicare-enrollment-101/"
                    style={{ color: '#1b0b54' }}
                  >
                    Medicare Enrollment Overview
                  </a>
                </Typography>
                <Typography variant="body">
                  Get fast facts about Medicare Enrollment, including when you
                  qualify and when to enroll.
                </Typography>
              </Column>
            </Columns>
          </Stack>
        </VariableContent>
      </>
    ),
  }

  return <Layout {...layoutProps} />
}
export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
